import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import PropTypes from "prop-types";

export default function ResourcesIndexPage({ data }) {
  return (
    <Layout>
      <SEO
        keywords={[`聖經介紹`, `聖經背景`, `聖經作者`, `聖經寫作目的`, `聖經主題`, `查經資料`]}
        title="主題查經"
        description="在你的屬靈生活裡，你也許希望多了解更多和聖經有關的資料，這裡提供各個主題的查經資料，助你更親近主。"
      />

      <section>
        <h1 className="text-center m-2 text-3xl font-bold m-3">主題查經</h1>
        <div className="m-2">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
            {data.allMarkdownRemark.edges.map((edge, index) => (
              <h3
                key={index}
                className={`bg-blue-600 text-white hover:bg-white hover:text-red-600 text-center p-2 m-1 text-xl`}
              >
                <Link to={`/resources/bible-study-articles/${edge.node.frontmatter.title}/`}>
                  {edge.node.frontmatter.title}
                </Link>
              </h3>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

ResourcesIndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
            })
          })
        })
      )
    }),
  }),
};


export const query = graphql`
  query  {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(bible-study-article)/"}}, sort: {fields: frontmatter___date, order: ASC}) {
      edges {
        node {
          frontmatter {
            title
          }
          id
        }
      }
    }
  }  
`;
